import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { UrlValidationGuard } from './shared/guards/url-validation.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'prize',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: [
      {
        path: 'prize',
        loadChildren: () => import('./modules/prize/prize.module').then((m) => m.PrizeModule),
      },
    ],
  },
  {
    path: 'profile',
    component: ContentLayoutComponent,
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'errors',
    component: ContentLayoutComponent,
    loadChildren: () => import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'information',
    component: ContentLayoutComponent,
    loadChildren: () => import('./modules/information/information.module').then((m) => m.InformationModule),
  },
  {
    path: 'game',
    component: ContentLayoutComponent,
    loadChildren: () => import('./modules/game/game.module').then((m) => m.GameModule),
  },
  {
    path: 'updates',
    component: ContentLayoutComponent,
    loadChildren: () => import('./modules/updates/updates.module').then((m) => m.UpdatesModule),
  },
  {
    path: 'payment',
    component: ContentLayoutComponent,
    canActivate: [UrlValidationGuard],
    loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: '**',
    redirectTo: '/errors/page-not-found',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

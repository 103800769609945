import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlValidationGuard implements CanActivate {
  private invalidUrlCharsRegex = /[^a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]/;

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const queryParams = next.queryParams;
    for (const param in queryParams) {
      if (this.invalidUrlCharsRegex.test(queryParams[param])) {
        this.router.navigate(['/errors/link-not-valid']);
        return false;
      }
    }

    return true;
  }
}
